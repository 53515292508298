import React from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'

const Magazine = () => {
  return (
    <div className='bg-[#121212]'>
        <Navbar />
        <div className='bg-[#121212] flex flex-col items-center text-white w-full'>
          <div className='flex mx-2 xl:mx-5 mt-5 xl:mt-10 mb-5 xl:mb-12'>
            <div className='bg-[#D9D9D9] rounded-3xl h-[270px] xl:h-[600px] w-2/5 xl:w-1/3'>
              <img src={require('../../Images/Cover.png')} className={'h-[270px] w-full rounded-3xl xl:h-[600px]'}/>
            </div>
            <div className='flex flex-col text-wrap w-3/5 xl:w-3/5 py-2 xl:py-10 text-left ml-2 xl:ml-8'>
              <h1 className={`text-['Montserrat'] font-bold text-base xl:text-3xl mb-1 xl:mb-4`}>ITIHAASA ISSUE 1</h1>
              <h1 className={`text-['Montserrat'] text-sm xl:text-3xl mb-1 xl:mb-4`}>March 2024</h1>
              <p className={`text-['Montserrat'] font-light text-[8px] sm:text-[12px] xl:text-2xl`}>
              We bring you 4 phenomenal articles from Bharat, one where an African slave becomes the Prime Minister of an Indian kingdom, second where we dive deep into the Deccani Sultanate to understand how administration worked back then and know the real history behind surnames like Deshmukh, Desai and Kotwal.
              In the third story, we discover a real life Sivagami from Bahubali, but her name here is Rani Chennamma of Kittur. And the fourth article is all about looking up! The Sun! But from a Vedic Perspective with absolutely solid proofs that ancient Indians knew all about the solar system and how the earth revolves around the sun!
              History brought to you as raw and unfiltered as possible! Grab your copy of Itihaasa today!
              Itihaasa is an upcoming organization dedicated to connect the younger generation of Bharath with her rich history and culture to rejuvinate the indic identity. We aim to contribute to the Indic renaissance and strive to be an unbiased source of History through the Itihaasa Magazine!
              </p>
              <div className='flex justify-end pt-3'>
              <button className={`bg-white rounded-3xl h-9 mb-10 w-1/2 text-[#3D3D3D] font-['Montserrat'] text-sm font-bold flex items-center justify-center sm:w-1/4 xl:w-1/5 xl:text-2xl xl:py-6 float-end`}
              onClick={() => window.open('https://www.amazon.in/Itihaasa-Magazine-Issue-March-History/dp/B0D17YRNFP/ref=sr_1_1?crid=3FWRNL0QF7UFS&dib=eyJ2IjoiMSJ9.dp6twcbG7Yo3JcjqR5_PCc2V14mhW7MU11tkfakyxbTRqZ13AzUnbd2pNil_i4Un3G2wvVy2r_0h7QYDI-9HIA.IvWdS-GP5EzJMW80-GE1ab7Ee1AfsF_6y1tVJAJNjE4&dib_tag=se&keywords=Itihaasa+Magazine&qid=1713201111&sprefix=itihaasa+magazin%2Caps%2C205&sr=8-1')}>
                Buy Now
              </button>
              </div>
            </div>
          </div>

          <h1 className={`font-['Montserrat'] font-bold text-xl mb-7 xl:mb-12 sm:text-3xl xl:text-5xl`}>GLIMPSES</h1>
          <div className={`flex justify-evenly w-full mb-12 xl:px-10`}>
            <div className='w-24 sm:w-52 xl:w-96'>
                <div className='bg-[#D9D9D9] h-36 rounded-3xl mb-4 sm:h-72 xl:h-[500px]'>
                  <img src={require('../../Images/BackCover.png')} className={'h-36 w-full rounded-3xl sm:h-72 xl:h-[500px]'}/>
                </div>
                <p className={`text-sm font-bold font-['Montserrat] text-center sm:text-xl`}>BACK COVER</p>
            </div>
            <div className='w-24 sm:w-52 xl:w-96'>
                <div className='bg-[#D9D9D9] h-36 rounded-3xl mb-4 sm:h-72 xl:h-[500px]'>
                  <img src={require('../../Images/InsideTheMagazine.png')} className={'h-36 w-full rounded-3xl sm:h-72 xl:h-[500px]'}/>
                </div>
                <p className={`text-sm font-bold font-['Montserrat] text-center sm:text-xl`}>INSIDE THE MAGAZINE</p>
            </div>
            <div className='w-24 sm:w-52 xl:w-96'>
                <div className='bg-[#D9D9D9] h-36 rounded-3xl mb-4 sm:h-72 xl:h-[500px]'>
                  <img src={require('../../Images/MeetTheTeam.png')} className={'h-36 w-full rounded-3xl sm:h-72 xl:h-[500px]'}/>
                </div>
                <p className={`text-sm font-bold font-['Montserrat] text-center sm:text-xl`}>MEET THE TEAM</p>
            </div>
        </div>
        </div>
        <Footer />
    </div>
  )
}

export default Magazine
