import React,{ useState, useEffect } from 'react';
import {Routes, Route} from'react-router-dom';
import Home from './components/home/home';
import Magazine from './components/Magazine/Magazine';

function App() {

  return (
    <Routes>
      <Route path="/" element={<Home />}/>
      <Route path="/Magazine" element = {<Magazine />}/>
    </Routes> 

  );
}

export default App;
