import React from 'react'
import { NavLink } from 'react-router-dom';
import { AiFillInstagram } from "react-icons/ai";
import { FaFacebookSquare } from "react-icons/fa";
import { IoLogoLinkedin } from "react-icons/io5";
import { FaXTwitter } from "react-icons/fa6";

const Footer = () => {
  return (
    <footer className={`bg-[#232323] flex flex-col text-white rounded-t-3xl w-full static bottom-0`}>
        <div className='flex py-6 px-3 flex-col sm:px-11'>
            <div className='flex mb-2'>
                <p className={`text-sm font-['Didact Gothic'] sm:text-2xl`}>Site Links:</p>
                <ul className={`flex text-[#808080] items-center text-xs ml-3 sm:ml-10 sm:text-base`}>
                <NavLink to='/'>
                        <li className=' mx-0.5 sm:mx-1'>Home</li>
                    </NavLink>
                    <NavLink to='/Magazine'>
                        <li className=' mx-0.5 sm:mx-1'>Magazines</li>
                    </NavLink>
                </ul>
            </div>
            <div className='flex'>
                <p className={`text-sm font-['Didact Gothic'] sm:text-2xl`}>Contact Us:</p>
                <ul className={`flex text-[#808080] items-center ml-5  text-xs sm:text-base`}>
                <li className='mx-1'><a href='mailto:team.itihaasa@gmail.com'>team.itihaasa@gmail.com</a></li>
                <li className='mx-3'><a href='tel:+917498421623'>+917498421623</a></li>
                </ul>
            </div>
        </div>
        <div className='flex border-dashed border-t-2 border-b-2 border-[#7F7F7F] py-1 px-5 sm:px-11'>
            <p className='text-6xl flex items-center sm:text-[84px]'>Itihaasa</p>
            <div className='flex items-end text-2xl ml-4 w-[150px] h-[70px] pb-3 justify-evenly sm:items-end sm:ml-12 sm:h-[70px] sm:pb-0 cursor-pointer'>
                <AiFillInstagram onClick={() => window.open('https://www.instagram.com/itihaasa_/')}/>
                <IoLogoLinkedin onClick={() => window.open('https://www.linkedin.com/company/itihaasaa/mycompany/')}/>
                <FaXTwitter onClick={() => window.open('https://x.com/itihaasa_?s=21&t=CpqJP9VXtBQPI0NB4p--vw')}/>
            </div>
        </div>
        <div className='h-6'></div>
    </footer>
  )
}

export default Footer
