import { NavLink } from "react-router-dom";
import { Menu, X } from "lucide-react";
import { useState } from "react";


const NavLinks = () => {
    return (
    <>
    <NavLink to="/" className={`flex justify-center text-white rounded-[131px] font-['Montserrat'] text-2xl w-40 mr-1 hover:bg-white hover:text-[#3D3D3D] px-3`} >HOME</NavLink>
    <NavLink to="/Magazine" className={`flex justify-center text-white rounded-[131px] font-['Montserrat'] text-2xl w-40 mr-3 hover:bg-white hover:text-[#3D3D3D] px-3`}>MAGAZINE</NavLink>     
    </>
    )
};

const Nav = () => {

    const [isOpen,setIsOpen] = useState(false);

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    }
    return (
        <>
        <nav className="w-1/3">
            <div className="md:flex w-3/4 hidden justify-between">
                <NavLinks/>
            </div>
            <div className="md:hidden ml-14">
                <button onClick={toggleNavbar} className="text-[#3D3D3D] bg-white rounded-md py-1 px-1 mb-4">
                    {isOpen ? <X/> : <Menu />}
                </button>
            </div>
        </nav>
        {isOpen &&(
            <div className="flex flex-col items-center w-full">
                <NavLinks />
            </div>
        )}
        </>
    )
};

export default Nav;