import React from 'react';
import { NavLink } from "react-router-dom";
import Nav from './Nav';

const Navbar = () => {
  return (
    <header className='bg-[#121212] sticky top-0 z-20 mx-auto w-full items-center justify-between flex flex-wrap pt-3 pb-1 xl:pb-3 xl:pt-10'>
      <div className={`mb-5 ml-7 w-1/2`}>
        <NavLink to="/">
          <img src={require('../../Images/Itihaasa Logo.png')} />
        </NavLink>
      </div>
      <Nav />
    </header>
  );
};

export default Navbar;
