import '../../App.css';
import { Link, NavLink } from 'react-router-dom';
import { Image } from 'lucide-react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

const Home = () => {

return(
<div className="App">
    <Navbar />
    <div className='bg-[#121212] flex flex-col items-center text-white mb-0 w-full'>
        <div className={`text-4xl mt-16 mb-14 sm:text-6xl xl:text-8xl xl:mb-24 font-bebas`}>
            <p>DIVE DEEPER INTO THE</p>
            <p>WORLD OF <span className='underline-offset-8 underline'>ITIHAASA</span></p>
        </div>
        <button className={`bg-white rounded-3xl h-12 mb-10 w-1/2 text-[#3D3D3D] font-['Montserrat'] text-lg font-bold py-6 flex items-center justify-center sm:w-1/4 xl:w-1/5 xl:text-2xl xl:py-6`}>
            <NavLink to='/Magazine'>
              OUR MAGAZINES
            </NavLink>
        </button>
        <div className={`font-plex w-full text-xs mb-16 sm:text-2xl xl:text-4xl xl:w-[1200px] xl:mb-28`}>
            <p>We are an organization dedicated in connecting the</p>
            <p>younger generation of Bharat with her rich history and</p>
            <p>culture to rejuvenate the Indic Identity</p>
        </div>
        <h1 className={`font-['Montserrat'] font-bold text-xl mb-12 sm:text-3xl xl:text-5xl`}>OUR LATEST MAGAZINE</h1>
        <div className={`flex justify-evenly  w-full mb-12 xl:px-10`}>
            <div className='w-24 sm:w-52 xl:w-96'>
                <div className='bg-[#D9D9D9] h-36 rounded-3xl mb-4 sm:h-72 xl:h-[500px]'>
                  <img src={require('../../Images/BackCover.png')} className={'h-36 w-full rounded-3xl sm:h-72 xl:h-[500px]'}/>
                </div>
                <p className={`text-xs font-bold font-['Montserrat] sm:text-xl`}>BACK COVER</p>
            </div>
            <div className='w-24 sm:w-52 xl:w-96'>
                <div className='bg-[#D9D9D9] h-36 rounded-3xl mb-4 sm:h-72 xl:h-[500px]'>
                  <img src={require('../../Images/InsideTheMagazine.png')} className={'h-36 w-full rounded-3xl sm:h-72 xl:h-[500px]'}/>
                </div>
                <p className={`text-xs font-bold font-['Montserrat] sm:text-xl`}>INSIDE THE MAGAZINE</p>
            </div>
            <div className='w-24 sm:w-52 xl:w-96'>
                <div className='bg-[#D9D9D9] h-36 rounded-3xl mb-4 sm:h-72 xl:h-[500px]'>
                  <img src={require('../../Images/MeetTheTeam.png')} className={'h-36 w-full rounded-3xl sm:h-72 xl:h-[500px]'}/>
                </div>
                <p className={`text-xs font-bold font-['Montserrat] sm:text-xl`}>MEET THE TEAM</p>
            </div>
        </div>
        <div>
            <h1 className={`text-4xl font-['Montserrat] mb-6 xl:text-6xl`}>Vision</h1>
            <p className='font-plex px-4 text-sm font-light mb-6 xl:mb-16 xl:text-4xl xl:px-16 '>
            To help people belong.
            </p>
            <p className='font-plex px-4 text-sm text-left font-light mb-1 xl:text-4xl xl:px-16'> स्वरूपव्याक्रियैव पराक्रिया (The Dynamic Principle we are working for)
                The best way to undermine the foundation of a false creed (History) and successfully attack it is to lay it open to the eyes of all and exhibit it as it really is. Error never retains its hold over the mind except under the mask of truth which it contrives to assume. When deprived of the mask that has covered its emptiness and unreality, it vanishes away as a phantom and an illusion.
            </p>
            <h1 className='font-plex text-sm ml-52 xl:text-2xl xl:ml-[1200px]'>~ K. V. Chellam</h1>
            <h1 className={`text-4xl font-['Montserrat] mb-6 mt-3 xl:text-6xl`}>Aim</h1>
            <p className='font-plex px-4 text-sm text-left font-light mb-10 xl:text-4xl xl:px-16'>We aim to contribute to the Indic renaissance and strive to be an unbiased source of history through our magazines.</p>
        </div>
        <Footer />
    </div>
    
    </div> 
);
};

export default Home;